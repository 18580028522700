import React, { useState, useEffect } from 'react';

import Instagram from '../Icons/Instagram.jsx';
import LogoIcon from '../Icons/Logo.jsx';

import './Footer.css';

const currentYear = new Date().getFullYear();


export default function Footer() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id='footer' className='flex flex-col bg-dark color-light p-4'>
            <div className='container'>
                <div className='flex justify-center items-center gap-4 mb-2'>
                    <LogoIcon />

                    <div className='flex flex-col'>
                        <p>&copy; {currentYear} - PATATAG®</p>
                        <p>Todos los derechos reservados</p>
                    </div>
                </div>

                <div className='content flex justify-between items-center flex-wrap text-center w-fit mx-auto'>
                    <p>Política de privacidad y aspectos legales</p>

                    {windowWidth > 460 ? (
                        <>
                            <span className='px-2'>|</span>
                            <p>Contacto</p>
                            <span className='px-2'>|</span>
                            <a href='https://www.instagram.com/patatag.ok/' target='_blank' rel='noreferrer'>
                                <Instagram />
                            </a>
                        </>
                    ) : (
                        <div className='flex items-center mt-1'>
                            <p>Contacto</p>
                            <span className='px-2'>|</span>
                            <a href='https://www.instagram.com/patatag.ok/' target='_blank' rel='noreferrer'>
                                <Instagram />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
